import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import showCustomToast from "./showCustomToast";
import CountdownSpinner from "./CountdownSpinner";

const VirtualTryOn = ({ isOpen, onClose, productImageUrl, userId }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [jobId, setJobId] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [galleryImage, setGalleryImage] = useState(null);
  const [currentStream, setCurrentStream] = useState(null);
  const [currentFacingMode, setCurrentFacingMode] = useState("user"); // "user" for front camera, "environment" for rear camera

  // Switch Camera functionality
  const handleSwitchCamera = async () => {
    try {
      // Stop the current stream completely
      if (currentStream) {
        currentStream.getTracks().forEach((track) => track.stop());
      }

      // Toggle between front and back cameras
      const newFacingMode = currentFacingMode === "user" ? "environment" : "user";
      setCurrentFacingMode(newFacingMode);

      // Request new media stream with the updated facing mode
      const constraints = {
        video: {
          facingMode: { exact: newFacingMode },
        },
      };

      // Try to get the new stream
      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      // Set the video source to the new stream
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setCurrentStream(stream);

        // Play the video after switching
        await videoRef.current.play();
      }
    } catch (error) {
      console.error("Error switching camera:", error);

      // Fallback to default camera if switch fails
      showCustomToast("Unable to switch camera. Reverting to default camera.", "error");

      try {
        const fallbackStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = fallbackStream;
          setCurrentStream(fallbackStream);
          await videoRef.current.play();
        }
      } catch (fallbackError) {
        console.error("Fallback error:", fallbackError);
        showCustomToast("Failed to access the camera. Please check permissions.", "error");
      }
    }
  };


  const [isGalleryImageSelected, setIsGalleryImageSelected] = useState(false);
  const {
    // eslint-disable-next-line no-unused-vars
    isOpen: isZoomOpen,
    // eslint-disable-next-line no-unused-vars
    onOpen: onZoomOpen,
    // eslint-disable-next-line no-unused-vars
    onClose: onZoomClose,
  } = useDisclosure();

  // Convert base64 to blob
  const base64ToBlob = (base64, mimeType) => {
    const byteString = atob(base64.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
  };

  // Resize and compress the image
  const resizeAndCompressImage = (
    imageDataUrl,
    maxWidth,
    maxHeight,
    quality
  ) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageDataUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        // Maintain aspect ratio
        if (width > maxWidth) {
          height = (height * maxWidth) / width;
          width = maxWidth;
        }
        if (height > maxHeight) {
          width = (width * maxHeight) / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        const compressedDataUrl = canvas.toDataURL("image/jpeg", quality);
        resolve(compressedDataUrl);
      };
    });
  };

  // Initialize the camera
  const initializeCamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;

          // Listen for the 'loadedmetadata' event before playing the video
          videoRef.current.onloadedmetadata = () => {
            videoRef.current.play().catch((error) => {
              console.error("Error playing video:", error);
            });
          };
        }
      })
      .catch((error) => {
        console.error("Error accessing the camera:", error);
      });
  };

  // Stop the camera
  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (!isGalleryImageSelected) {
        initializeCamera();
      }
    } else {
      stopCamera();
      setCapturedImage(null);
      setProcessedImage(null);
      setIsLoading(false);
      setCountdown(0);
      setIsCountdownActive(false);
      setGalleryImage(null); // Clear gallery image when closing modal
      setIsGalleryImageSelected(false); // Reset gallery selection state
    }
    return () => stopCamera();
  }, [isOpen, isGalleryImageSelected]);

  const handleCapturePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      try {
        // Set canvas dimensions based on the video dimensions
        const aspectRatio = video.videoWidth / video.videoHeight || 1;
        canvas.width = 640;
        canvas.height = 640 / aspectRatio;

        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const image = canvas.toDataURL("image/png");
        setCapturedImage(image);
        stopCamera();
      } catch (error) {
        console.error("Error capturing photo:", error);
        showCustomToast("Failed to capture photo. Please try again.", "error");
      }
    }
  };

  const startCountdown = () => {
    setIsCountdownActive(true);
    setCountdown(3);
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer);
          setIsCountdownActive(false);
          handleCapturePhoto();
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Clear the captured photo and reset states
  const handleClearPhoto = () => {
    setCapturedImage(null);
    setGalleryImage(null); // Clear gallery image
    setProcessedImage(null);
    setJobId(null);
    setIsLoading(false);
    setIsGalleryImageSelected(false); // Reset gallery selection state
    initializeCamera(); // Reinitialize camera
  };

  // Submit the captured image for processing
  const handleProcessTryOnImage = async (image) => {
    // setIsLoading(true);
    setIsSubmitLoading(true);
    const compressedImage = await resizeAndCompressImage(image, 800, 800, 0.8);
    const blob = base64ToBlob(compressedImage, "image/jpeg");
    const formData = new FormData();
    formData.append("product_image", productImageUrl);
    formData.append("user_id", userId);
    formData.append("user_image", blob, "user_image.jpg");

    try {
      const apiURL = `${process.env.REACT_APP_API}/processVirtualTryOnImage`;
      const response = await axios.post(apiURL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.status_code === 2) {
        showCustomToast(response.data.message, "error");
        setIsSubmitLoading(false);
      } else {
        const jobIdFromResponse = response.data.data.result.job_id;
        setJobId(jobIdFromResponse);
        checkImageProcessingStatus(jobIdFromResponse, userId);
      }
    } catch (error) {
      console.error("Error processing try-on image:", error);
      showCustomToast("Error in processing try-on image", "error");
      // setIsLoading(false);
      setIsSubmitLoading(false);
    }
  };

  // Polling function to check the status until the image is ready
  const checkImageProcessingStatus = async (jobId, userId) => {
    const apiURL = `${process.env.REACT_APP_API}/fetchProcessedImage`;
    const interval = 8000;

    const pollStatus = async () => {
      try {
        const response = await axios({
          method: "post",
          url: apiURL,
          data: {
            job_id: jobId,
            user_id: userId.toString(),
          },
          headers: { "Content-Type": "application/json" },
        });

        if (response.data.status_code === 1) {
          setProcessedImage(response.data.data.output_image_url[0]);
          // setIsLoading(false);
          setIsSubmitLoading(false);
        } else if (response.data.status_code === 2) {
          setTimeout(pollStatus, interval);
          // showCustomToast("Error in processing image", "error");
        } else {
          showCustomToast("Error in processing image", "error");
          // setIsLoading(false);
          setIsSubmitLoading(false);
        }
      } catch (error) {
        console.error("Error in fetching processed image:", error);
        // setIsLoading(false);
        setIsSubmitLoading(false);
      }
    };

    pollStatus();
  };

  // Trigger the process when "Submit" is clicked
  const handleSubmit = async () => {
    const imageToProcess = galleryImage || capturedImage;
    if (imageToProcess) {
      await handleProcessTryOnImage(imageToProcess);
    }
    // if (capturedImage) {
    //   handleProcessTryOnImage(capturedImage);
    // }
  };
  const handleGallerySelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        try {
          setGalleryImage(reader.result);
          setIsGalleryImageSelected(true);
          stopCamera();
        } catch (error) {
          console.error("Error loading gallery image:", error);
          showCustomToast(
            "Failed to load image. Please try a different file.",
            "error"
          );
        }
      };
      reader.onerror = () => {
        showCustomToast("Error reading file. Please try again.", "error");
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent position="relative">
        <CloseButton
          position="absolute"
          top="8px"
          right="8px"
          onClick={onClose}
        />
        <ModalHeader>
          Virtual Try On
          {
            !processedImage && !capturedImage && !galleryImage  &&  <Button
            ml="10px" // Margin to add space between the header text and button
            size="sm"
            onClick={handleSwitchCamera}
            colorScheme="blue"
            variant="outline"
          >
            Switch Camera
          </Button>
          }

        </ModalHeader>
        <ModalBody>
          {isLoading || isSubmitLoading ? (
            <Box position="relative">
              <img
                src={capturedImage || galleryImage}
                alt="Captured preview"
                style={{ width: "100%", maxHeight: "100%" }}
              />
              <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                zIndex="9999"
              >
                {/* Spinner */}
                <Spinner size="xl" color="pink.500" />

                {/* Loading message below the spinner */}
                {isSubmitLoading && (
                  <Box
                    marginTop="10px" // Space between the spinner and the text
                    fontSize="1.3rem"
                    fontWeight="bold"
                    color="white"
                    textAlign="center"
                  >
                    ⏳ It usually takes 30 sec, Hold Tight!
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <>
              <Box>
                {!processedImage && (
                  <>
                    {!capturedImage && !galleryImage ? (
                      // Display video camera if no image is captured or selected from gallery
                      <video
                        ref={videoRef}
                        autoPlay
                        muted
                        playsInline
                        style={{ width: "100%", maxHeight: "100%" }}
                      />
                    ) : (
                      // Display the captured image or gallery image if available
                      <img
                        src={capturedImage || galleryImage}
                        alt="Captured preview"
                        style={{ width: "100%", maxHeight: "100%" }}
                      />
                    )}
                  </>
                )}

                <canvas
                  ref={canvasRef}
                  style={{ display: "none" }}
                  width={640}
                  height={480}
                />
              </Box>

              {processedImage && (
                <Box mt="20px">
                  <img
                    src={processedImage}
                    alt="Processed virtual try-on result"
                    style={{ width: "100%", maxHeight: "100%" }}
                    onClick={onZoomOpen}
                    cursor="pointer"
                  />
                </Box>
              )}
            </>
          )}

          {isCountdownActive && (
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              zIndex="1000"
            >
              <CountdownSpinner countdown={countdown} />
              {/* "Say Cheese!" text */}
              <Box
                marginTop="10px" // Space between the spinner and the text
                fontSize="1.5rem"
                fontWeight="bold"
                color="white"
              >
                <span role="img" aria-label="camera emoji">
                  😃
                </span>{" "}
                Say Cheese!
              </Box>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          {!capturedImage && !isGalleryImageSelected ? (
            <>
              <Button
                onClick={startCountdown}
                color="#fff"
                borderRadius="5px"
                p="20px 35px"
                bgGradient={"linear(to-r, red.500, red.700)"}
                variant="solid"
                transition="all 0.3s ease-in-out"
                _hover={{
                  bgGradient:"linear(to-r, red.400, red.600)",
                  transform: "scale(1.05)",
                  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.4)",
                }}
                _active={{
                  transform: "scale(0.98)",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                Capture Photo
              </Button>

              <Button
                color="#fff"
                borderRadius="5px"
                p="20px 35px"
                bgGradient={"linear(to-r, red.500, red.700)"}
                variant="solid"
                transition="all 0.3s ease-in-out"
                _hover={{
                  bgGradient:"linear(to-r, red.400, red.600)",
                  transform: "scale(1.05)",
                  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.4)",
                }}
                _active={{
                  transform: "scale(0.98)",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                }}
                ml={2}
                as="label"
                htmlFor="gallery-upload"
              >
                Select from Gallery
              </Button>
              <input
                type="file"
                id="gallery-upload"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleGallerySelect}
              />
            </>
          ) : (
            <>
              <Button
                onClick={handleSubmit}
                color="#fff"
                borderRadius="5px"
                p="20px 35px"
                bgGradient={"linear(to-r, red.500, red.700)"}
                variant="solid"
                transition="all 0.3s ease-in-out"
                _hover={{
                  bgGradient:"linear(to-r, red.400, red.600)",
                  transform: "scale(1.05)",
                  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.4)",
                }}
                _active={{
                  transform: "scale(0.98)",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                Submit
              </Button>
              <Button
                onClick={handleClearPhoto}
                color="#fff"
                borderRadius="5px"
                p="20px 35px"
                bgGradient={"linear(to-r, red.500, red.700)"}
                variant="solid"
                transition="all 0.3s ease-in-out"
                _hover={{
                  bgGradient:"linear(to-r, red.400, red.600)",
                  transform: "scale(1.05)",
                  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.4)",
                }}
                _active={{
                  transform: "scale(0.98)",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                }}
                ml={2}
              >
                Clear
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VirtualTryOn;
